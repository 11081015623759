<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout>
      <Loader v-if="showloader && show_guardian == 0"></Loader>
      <v-flex xs12 sm12 v-else>
        <v-card outlined class="primary--border">
          <div style="display: flex">
            <div
              class="overlay"
              :style="
                sideBarClosed ||
                (!(
                  this.form.studentInfo.data.fname &&
                  this.form.studentInfo.data.lname &&
                  this.form.studentInfo.data.gender &&
                  this.form.studentInfo.data.dob &&
                  this.form.studentInfo.data.religion &&
                  this.form.studentInfo.data.ethnicity
                ) &&
                  !step_2_complete &&
                  !isStep3Complete &&
                  !isStep4Complete)
                  ? 'width: 100%'
                  : 'width: 75%'
              "
            >
              <v-card-title class="title pb-0">
                <div>
                  New admission
                  <v-calendar-field
                    v-if="admission_date_dialogue === true"
                    :disablePreviousDate="false"
                    id-val="admissionDateSelect"
                    label="Select Admission Date"
                    v-model="admission_date_temp"
                    :margin="2"
                  >
                  </v-calendar-field>

                  <!-- <load-button  @action="admitStudent" :loader="true" color="primary" text >Save</load-button> -->

                  <p
                    style="
                      font-size: 12px;
                      margin-top: 2px;
                      color: #777;
                      line-height: 1;
                    "
                  >
                    Following student will be added for
                    <span style="color: indianred">{{
                      batch.name ? batch.name.toLowerCase() : ""
                    }}</span>
                    with
                    <span style="color: indianred"
                      >{{ admission_date }}
                      <span v-if="admission_date === admission_date_today"
                        >(today)</span
                      >
                      <a
                        v-if="admission_date_dialogue === false"
                        style="padding-right: 5px; padding-left: 5px"
                        href=""
                        @click.prevent="
                          admission_date_dialogue = !admission_date_dialogue
                        "
                      >
                        &nbsp;&nbsp;
                        <span>
                          [<v-icon
                            v-if="admission_date_dialogue === false"
                            color="red"
                            size="small"
                            >edit</v-icon
                          >]
                        </span>
                      </a>
                      <a
                        v-else
                        style="padding-right: 5px; padding-left: 5px"
                        href=""
                        @click.prevent="
                          (admission_date = admission_date_temp),
                            (admission_date_dialogue = false)
                        "
                      >
                        &nbsp;&nbsp;
                        <span>
                          [<v-icon color="red" size="small">check</v-icon>]
                        </span>
                      </a>
                      <a
                        title="Reset (today)"
                        style="padding-right: 5px; padding-left: 0px"
                        href=""
                        @click.prevent="
                          (admission_date_dialogue = false),
                            (admission_date = admission_date_today),
                            (admission_date_temp = admission_date_today)
                        "
                        v-if="
                          admission_date !== admission_date_today ||
                          admission_date_temp !== admission_date_today
                        "
                      >
                        &nbsp;&nbsp;
                        <span
                          >[<v-icon color="red" size="small">close</v-icon
                          >]</span
                        >
                      </a>
                    </span>
                    as admission date.
                  </p>
                </div>
                <v-spacer></v-spacer>
                <v-btn outlined small color="warning" @click="downloadAdmissionForm()">Download Form Instead</v-btn>
              </v-card-title>
              <v-card-title
                v-if="admission_date !== admission_date_today"
                style="max-width: 980px"
              >
                <alert-message
                  :extra-small="true"
                  :type="'warning'"
                  title="Admission Date Warning"
                  message="Please be fully aware of what you are doing. Changing the admission date irrespective today might hamper billing/exam/academic data of the student."
                ></alert-message>
              </v-card-title>

              <v-stepper class="elevation-0" v-model="step" vertical>
                <v-stepper-step step="1" complete
                  >Student Information</v-stepper-step
                >
                <v-stepper-content step="1">
                  <v-form
                    v-model="form.studentInfo.validation.valid"
                    ref="studentInfoForm"
                    lazy-validation
                  >
                    <v-container class="pl-0" fluid grid-list-md>
                      <v-layout row wrap>
                        <v-flex xs12 sm3 md3>
                          <v-text-field
                            label="First Name"
                            required
                            v-model="form.studentInfo.data.fname"
                            outlined
                            dense
                            autocomplete="fname"
                            :rules="form.studentInfo.validation.fnameRule"
                          />
                        </v-flex>
                        <v-flex xs12 sm3 md3>
                          <v-text-field
                            label="Middle Name"
                            outlined
                            dense
                            v-model="form.studentInfo.data.mname"
                            autocomplete="midname"
                          />
                        </v-flex>
                        <v-flex xs12 sm3 md3>
                          <v-text-field
                            label="Last Name"
                            required
                            outlined
                            dense
                            autocomplete="lastname"
                            v-model="form.studentInfo.data.lname"
                            :rules="form.studentInfo.validation.lnameRule"
                          />
                        </v-flex>
                        <v-flex xs12 sm3 md3>
                          <v-autocomplete
                            outlined
                            dense
                            :items="gender"
                            v-model="form.studentInfo.data.gender"
                            label="Gender"
                            data-vv-name="select"
                            :rules="form.studentInfo.validation.genderRule"
                            required
                            autocomplete="gender"
                          />
                        </v-flex>

                        <v-flex xs12 sm2 md2>
                          <v-calendar-field
                            v-if="
                              typeof form.studentInfo.data.dob === 'string' ||
                              show_guardian === 1
                            "
                            :margin="0"
                            id-val="dob_unique_id"
                            v-model="form.studentInfo.data.dob"
                            label="Date of Birth"
                            autocomplete="dob_un"
                          >
                          </v-calendar-field>
                        </v-flex>
                        <v-flex xs12 sm2 md2>
                          <v-autocomplete
                            outlined
                            dense
                            :items="religions"
                            v-model="form.studentInfo.data.religion"
                            autocomplete="religion"
                            :rules="form.studentInfo.validation.religionRule"
                            label="Religion"
                            data-vv-name="select"
                          />
                        </v-flex>

                        <v-flex xs12 sm2 md2>
                          <v-autocomplete
                            autocomplete="bloodGroup"
                            outlined
                            dense
                            :items="blood"
                            v-model="form.studentInfo.data.blood_group"
                            label="Blood Group"
                            data-vv-name="select"
                          />
                        </v-flex>
                        <v-flex xs12 sm2 md2>
                          <v-autocomplete
                            autocomplete="ethinicity"
                            outlined
                            dense
                            :items="ethnicities"
                            v-model="form.studentInfo.data.ethnicity"
                            label="Ethnicity"
                            data-vv-name="select"
                            :rules="form.studentInfo.validation.ethnicityRule"
                            required
                          />
                        </v-flex>

                        <v-flex xs12 sm3 md3>
                          <image-picker
                            label="Profile Image"
                            ref="picker"
                            :height="200"
                            :width="200"
                            :ratio="1"
                            @result="cropImage"
                          ></image-picker>

                          <v-card v-if="cropperImg.cropImg">
                            <v-card-text>
                              <img :src="cropperImg.cropImg" height="100" />
                              <v-btn
                                fab
                                small
                                bottom
                                right
                                absolute
                                @click.native.stop="resetImagePicker"
                                style="z-index: 1"
                                color="red white--text"
                              >
                                <v-icon dark>remove</v-icon>
                              </v-btn>
                            </v-card-text>
                          </v-card>
                        </v-flex>
                      </v-layout>
                    </v-container>
                    <v-btn outlined @click="resetStudentInfoForm">Reset</v-btn
                    >&nbsp;
                    <v-btn
                      color="primary"
                      outlined
                      @click.native="submitStudentInfoForm"
                      :disabled="!form.studentInfo.validation.valid"
                      >Next
                    </v-btn>
                  </v-form>
                </v-stepper-content>

                <v-stepper-step step="2" :complete="step > 2">
                  <span
                    >Family &amp; Relationship Information
                    <a
                      v-if="step === 2"
                      href=""
                      @click.prevent="guardianForm.dialog = true"
                      style="text-decoration: none"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon
                            v-on="on"
                            style="line-height: 20px; color: #888"
                            class="lighten-6"
                            >add_circle</v-icon
                          >
                        </template>
                        <span>Quick Add Guardian</span>
                      </v-tooltip>
                    </a>
                  </span>
                </v-stepper-step>
                <v-stepper-content step="2">
                  <v-form
                    v-model="form.familyInfo.validation.valid"
                    ref="familyInfoForm"
                    lazy-validation
                  >
                    <!-- v-model="form.familyInfo.data.guardian_id" -->

                    <v-container class="pl-0" grid-list-md>
                      <v-layout row wrap>
                        <v-flex xs12 sm6 md6>
                          <v-autocomplete
                            outlined
                            dense
                            :loading="guardianLoading"
                            :items="guardianItems"
                            v-model="form.familyInfo.data.guardian_id"
                            label="Select Guardian"
                            item-text="Name"
                            item-value="id"
                            data-vv-name="select"
                            :rules="form.familyInfo.validation.guardian_id"
                            cache-items
                            hide-no-data
                            :search-input.sync="searchGuardian"
                          />
                        </v-flex>
                        <v-flex xs12 sm6 md6>
                          <v-autocomplete
                            outlined
                            dense
                            :items="relations"
                            v-model="form.familyInfo.data.relation"
                            label="Relation"
                            :rules="form.familyInfo.validation.relation"
                            data-vv-name="select"
                          />
                        </v-flex>
                        <v-flex
                          xs12
                          v-if="guardian && form.familyInfo.data.guardian_id"
                        >
                          <v-card color="cyan darken-2" class="white--text">
                            <v-container fluid grid-list-lg>
                              <v-layout row>
                                <v-flex xs3 v-if="guardian && guardian.image">
                                </v-flex>
                                <v-flex xs9>
                                  <div>
                                    <div class="headline">
                                      {{ guardianSelected.name }}
                                    </div>
                                    <div>{{ guardian.dob }}</div>
                                    <div style="height: 15px"></div>
                                    <span
                                      ><strong>Gender: </strong>
                                      {{
                                        guardianSelected.gender | capitalize
                                      }}</span
                                    ><br />
                                    <span v-if="guardianSelected.email"
                                      ><strong>E-mail: </strong>
                                      {{ guardianSelected.email }}<br
                                    /></span>
                                    <span
                                      ><strong>Mobile: </strong>
                                      {{
                                        guardianSelected.primary_mobile
                                      }}</span
                                    ><br />
                                    <span
                                      ><strong>Profession: </strong>
                                      {{
                                        (guardianSelected.occupation &&
                                        guardianSelected.occupation !== "null"
                                          ? guardianSelected.occupation
                                          : "") | capitalize
                                      }}</span
                                    >
                                  </div>
                                </v-flex>
                              </v-layout>
                            </v-container>
                          </v-card>
                        </v-flex>
                      </v-layout>
                      <!-- <v-layout v-else> -->
                      <!-- <v-layout>
                        <v-flex xs12 sm12>
                           <v-card elevation="4">
                            <v-card-title class="title">
                                Guardian Details
                                <v-spacer></v-spacer>
                            </v-card-title>
                               <v-card-text>
                                <h3>Name: {{guardianForm.fname}} {{guardianForm.mname}} {{guardianForm.lname}}</h3>
                                <div>
                                    <p>Contact :{{ guardianForm.primary_mobile}}</p>
                                </div>
                              </v-card-text>
                           </v-card>
                        </v-flex>
                      </v-layout> -->
                    </v-container>

                    <v-btn @click="step--" outlined>Previous</v-btn>&nbsp;
                    <v-btn @click="$refs.familyInfoForm.reset()" outlined
                      >Reset</v-btn
                    >&nbsp;
                    <v-btn
                      color="primary"
                      @click.native="submitFamilyInfoForm"
                      outlined
                      >Next</v-btn
                    >
                  </v-form>
                </v-stepper-content>

                <v-stepper-step step="3" :complete="step > 3"
                  >Address Information</v-stepper-step
                >
                <v-stepper-content step="3">
                  <v-form
                    v-model="form.addressInfo.validation.valid"
                    ref="addressInfoForm"
                    lazy-validation
                  >
                    <v-container class="pl-0" grid-list-md>
                      <v-layout row wrap>
                        <v-flex xs12 sm12 md12>
                          <strong style="margin-bottom: -5px"
                            >Permanent Address</strong
                          >
                        </v-flex>
                        <v-flex xs12 sm4>
                          <v-autocomplete
                            outlined
                            dense
                            :items="states"
                            v-model="form.addressInfo.data.permanent_state_id"
                            label="Province/State"
                            data-vv-name="select"
                            :rules="
                              form.addressInfo.validation
                                .permanent_state_id_rule
                            "
                            required
                          />
                        </v-flex>
                        <v-flex xs12 sm4 md4>
                          <v-autocomplete
                            outlined
                            dense
                            :items="permanent_districts"
                            v-model="
                              form.addressInfo.data.permanent_district_id
                            "
                            label="District"
                            data-vv-name="select"
                            :rules="
                              form.addressInfo.validation
                                .permanent_district_id_rule
                            "
                            required
                          />
                        </v-flex>
                        <v-flex xs12 sm4 md4>
                          <v-autocomplete
                            outlined
                            dense
                            :items="permanent_cities"
                            v-model="form.addressInfo.data.permanent_city_id"
                            label="Municipality/City"
                            data-vv-name="select"
                            :rules="
                              form.addressInfo.validation.permanent_city_id_rule
                            "
                            required
                          />
                        </v-flex>
                        <v-flex xs12 sm2>
                          <v-autocomplete
                            outlined
                            dense
                            :items="ward_count_permanent"
                            :rules="
                              form.addressInfo.validation.permanent_ward_no
                            "
                            v-model="form.addressInfo.data.permanent_ward_no"
                            label="Ward"
                            data-vv-name="select"
                            required
                          />
                        </v-flex>
                        <v-flex xs12 sm4 md4>
                          <v-text-field
                            label="Street/Tole"
                            outlined
                            dense
                            v-model="
                              form.addressInfo.data.permanent_address_line
                            "
                            autocomplete="streetTole"
                            :rules="
                              form.addressInfo.validation
                                .permanent_address_line_rule
                            "
                          />
                        </v-flex>
                        <br />
                        <v-flex xs12 sm12 md12
                          ><strong style="margin-bottom: -5px"
                            >Current Address</strong
                          ></v-flex
                        >
                        <v-flex xs12>
                          <v-checkbox
                            class="mt-0"
                            outlined
                            dense
                            label="Mark current address same as permanent"
                            v-model="same_address"
                            :disabled="
                              !form.addressInfo.data.permanent_address_line ||
                              !form.addressInfo.data.permanent_city_id
                            "
                          />
                        </v-flex>
                        <v-flex xs12 sm4>
                          <v-autocomplete
                            outlined
                            dense
                            :items="states"
                            v-model="form.addressInfo.data.temporary_state_id"
                            label="Province/State"
                            data-vv-name="select"
                            :rules="
                              form.addressInfo.validation
                                .temporary_state_id_rule
                            "
                            required
                          />
                        </v-flex>
                        <v-flex xs12 sm4 md4>
                          <v-autocomplete
                            outlined
                            dense
                            :items="temporary_districts"
                            v-model="
                              form.addressInfo.data.temporary_district_id
                            "
                            label="District"
                            data-vv-name="select"
                            :rules="
                              form.addressInfo.validation
                                .temporary_district_id_rule
                            "
                            required
                          />
                        </v-flex>
                        <v-flex xs12 sm4 md4>
                          <v-autocomplete
                            outlined
                            dense
                            :items="temporary_cities"
                            v-model="form.addressInfo.data.temporary_city_id"
                            label="Municipality/City"
                            data-vv-name="select"
                            :rules="
                              form.addressInfo.validation.temporary_city_id_rule
                            "
                            required
                          />
                        </v-flex>

                        <v-flex xs12 sm2>
                          <v-autocomplete
                            outlined
                            dense
                            :items="ward_count_temporary"
                            :rules="
                              form.addressInfo.validation.temporary_ward_no
                            "
                            v-model="form.addressInfo.data.temporary_ward_no"
                            label="Ward"
                            data-vv-name="select"
                            required
                          />
                        </v-flex>
                        <v-flex xs12 sm4 md4>
                          <v-text-field
                            label="Street/Tole"
                            outlined
                            dense
                            v-model="
                              form.addressInfo.data.temporary_address_line
                            "
                            autocomplete="tempstreetTole"
                            :rules="
                              form.addressInfo.validation
                                .temporary_address_line_rule
                            "
                          />
                        </v-flex>
                      </v-layout>
                    </v-container>
                    <v-btn @click="step--" outlined>Previous</v-btn>&nbsp;
                    <v-btn @click="$refs.addressInfoForm.reset()" outlined
                      >Reset</v-btn
                    >&nbsp;
                    <v-btn
                      color="primary"
                      outlined
                      @click.native="submitAddressInfoForm"
                      :disabled="!form.addressInfo.validation.valid"
                      >Next
                    </v-btn>
                  </v-form>
                </v-stepper-content>
                <v-stepper-step step="4" v-bind:complete="step > 4"
                  >School/College Enroll Information
                </v-stepper-step>
                <v-stepper-content step="4">
                  <v-form
                    v-model="form.enrollInfo.validation.valid"
                    ref="enrollInfoForm"
                    lazy-validation
                  >
                    <v-container class="pl-0" grid-list-md>
                      <v-layout row wrap>
                        <!--<v-flex xs12 sm2 md2>-->
                        <!--<v-autocomplete-->
                        <!--:items="levels"-->
                        <!--v-model="base"-->
                        <!--label="Select Level"-->
                        <!--data-vv-name="select"-->
                        <!--:rules="form.enrollInfo.validation.base_idRule"-->
                        <!--required-->
                        <!--/>-->
                        <!--</v-flex>-->
                        <v-flex xs4>
                          <v-autocomplete
                            outlined
                            dense
                            :disabled="grades.length < 1"
                            :items="grades"
                            v-model="grade"
                            label="Select Class"
                            data-vv-name="select"
                            :rules="form.enrollInfo.validation.grade_idRule"
                            required
                          />
                        </v-flex>
                        <v-flex xs4>
                          <v-autocomplete
                            outlined
                            dense
                            :disabled="sections.length < 1"
                            :items="sections"
                            v-model="section"
                            label="Section"
                            data-vv-name="select"
                            :rules="form.enrollInfo.validation.section_idRule"
                            required
                          />
                        </v-flex>
                        <v-flex xs4>
                          <v-autocomplete
                            outlined
                            dense
                            multiple
                            :disabled="optional_groups.length < 1"
                            :items="optional_groups"
                            v-model="optional_sub"
                            label="Optional Group"
                            data-vv-name="select"
                            required
                          />
                        </v-flex>
                        <v-flex xs4>
                          <v-autocomplete
                            autocomplete="off"
                            outlined
                            dense
                            :items="studentGroups"
                            v-model="studentGroupId"
                            label="Student Programme / Groups"
                            data-vv-name="select"
                          />
                        </v-flex>
                        <v-flex xs12>
                          <v-card dark>
                            <v-card-text>
                              <strong class="red--text">Note:</strong>
                              <br />
                              <small>
                                Roll No. will be automatically generated
                                according to the admission hierarchy. You'll be
                                able to change the roll no. later if needed!
                              </small>
                            </v-card-text>
                          </v-card>
                        </v-flex>
                      </v-layout>
                    </v-container>
                    <v-divider></v-divider>
                    <br />
                    <v-btn @click="step--" outlined>Previous</v-btn>&nbsp;
                    <v-btn @click="$refs.enrollInfoForm.reset()" outlined
                      >Reset</v-btn
                    >&nbsp;
                    <load-button
                      @action="admitStudent"
                      :loader="loader"
                      color="primary"
                      text
                      >Admit</load-button
                    >
                  </v-form>
                </v-stepper-content>
              </v-stepper>
              <div
                :class="'inner-overlay ' + (isCurrentBatch ? 'dont-show' : '')"
              >
                <p>
                  <v-icon style="font-size: 50px; color: red">warning</v-icon>
                  <br />
                  New admissions are allowed only in the current batch. <br />
                  <span style="font-size: 15px"
                    >Please make sure you have current batch selected in your
                    action bar.</span
                  >
                </p>
              </div>
            </div>

            <div
              :style="
                sideBarClosed ||
                (!(
                  this.form.studentInfo.data.fname &&
                  this.form.studentInfo.data.lname &&
                  this.form.studentInfo.data.gender &&
                  this.form.studentInfo.data.dob &&
                  this.form.studentInfo.data.religion &&
                  this.form.studentInfo.data.ethnicity
                ) &&
                  !step_2_complete &&
                  !isStep3Complete &&
                  !isStep4Complete)
                  ? 'width: 5%'
                  : 'width: 25%'
              "
              class="info-student info-student-details"
            >
              <div
                style="
                  text-align: right;
                  padding-right: 10px;
                  padding-top: 10px;
                  margin-bottom: -10px;
                "
              >
                <a
                  v-if="
                    (this.form.studentInfo.data.fname &&
                      this.form.studentInfo.data.lname &&
                      this.form.studentInfo.data.gender &&
                      this.form.studentInfo.data.dob &&
                      this.form.studentInfo.data.religion &&
                      this.form.studentInfo.data.ethnicity) ||
                    step_2_complete ||
                    isStep3Complete ||
                    isStep4Complete
                  "
                  href=""
                  style="text-align: right; text-decoration: none"
                  @click.prevent="sideBarClosed = !sideBarClosed"
                >
                  <v-icon v-if="sideBarClosed">add</v-icon>
                  <v-icon v-else>close</v-icon>
                </a>
              </div>

              <v-timeline
                v-if="!sideBarClosed"
                class="user_add_form"
                align-top
                dense
              >
                <v-timeline-item
                  v-if="
                    this.form.studentInfo.data.fname &&
                    this.form.studentInfo.data.lname &&
                    this.form.studentInfo.data.gender &&
                    this.form.studentInfo.data.dob &&
                    this.form.studentInfo.data.religion &&
                    this.form.studentInfo.data.ethnicity
                  "
                  :color="step !== 1 ? 'green' : 'red'"
                  small
                >
                  <v-layout pt-1>
                    <v-flex
                      :class="step !== 1 ? 'progress_shaded' : ''"
                      style="margin-top: 0; margin-right: 5px"
                    >
                      <h3
                        style="
                          color: #444;
                          border-bottom: 1px dashed #ccc;
                          margin-bottom: 10px;
                          padding-bottom: 5px;
                        "
                      >
                        <strong>Student Information</strong>
                        <v-icon
                          style="font-size: 20px; color: #999; float: right"
                          >account_circle
                        </v-icon>
                      </h3>
                      <div class="caption">
                        <strong style="font-size: 16px">
                          {{ form.studentInfo.data.fname }}
                          {{ form.studentInfo.data.mname }}
                          {{ form.studentInfo.data.lname }}
                        </strong>
                      </div>
                      <div
                        style="
                          display: flex;
                          justify-content: space-between;
                          color: #666;
                        "
                      >
                        <div style="flex: 1.5" class="caption">
                          <strong>Gender </strong>
                          <p>
                            {{
                              form.studentInfo.data.gender
                                ? form.studentInfo.data.gender.capitalizeFirstLetter()
                                : ""
                            }}
                          </p>
                        </div>

                        <div style="flex: 1" class="caption">
                          <strong>D0B </strong>
                          <p>
                            {{
                              form.studentInfo.data.dob
                                ? form.studentInfo.data.dob.dateForm()
                                : ""
                            }}
                          </p>
                        </div>
                      </div>
                      <div
                        style="
                          display: flex;
                          justify-content: space-between;
                          color: #666;
                        "
                      >
                        <div style="flex: 1.5" class="caption">
                          <strong>Religion </strong>
                          <p>{{ form.studentInfo.data.religion }}</p>
                        </div>
                        <div style="flex: 1" class="caption">
                          <strong>Ethnicity </strong>
                          <p>{{ form.studentInfo.data.ethnicity }}</p>
                        </div>
                      </div>
                      <div
                        style="
                          display: flex;
                          justify-content: space-between;
                          color: #666;
                        "
                      >
                        <div style="flex: 1.5" class="caption">
                          <strong>Blood Group </strong>
                          <p>{{ form.studentInfo.data.blood_group }}</p>
                        </div>
                      </div>
                    </v-flex>
                  </v-layout>
                </v-timeline-item>
                <v-timeline-item
                  v-if="step_2_complete"
                  :color="step !== 2 ? 'green' : 'red'"
                  small
                >
                  <v-layout pt-3>
                    <v-flex
                      :class="step !== 2 ? 'progress_shaded' : ''"
                      style="
                        margin-top: -10px;
                        margin-left: 0px;
                        margin-right: 5px;
                      "
                    >
                      <h3
                        style="
                          color: #444;
                          border-bottom: 1px dashed #ccc;
                          margin-bottom: 10px;
                          padding-bottom: 5px;
                        "
                      >
                        <strong>Guardian</strong>
                        <v-icon
                          style="font-size: 20px; color: #999; float: right"
                        >
                          supervised_user_circle
                        </v-icon>
                      </h3>
                      <div class="caption">
                        <strong style="font-size: 16px">
                          {{ guardianSelected.name }}
                        </strong>
                      </div>

                      <div
                        style="
                          display: flex;
                          justify-content: space-between;
                          color: #666;
                        "
                      >
                        <div style="flex: 1.5" class="caption">
                          <strong>Gender </strong>
                          <p>{{ guardianSelected.gender | capitalize }}</p>
                        </div>

                        <div style="flex: 1" class="caption">
                          <strong>Relation </strong>
                          <p>{{ form.familyInfo.data.relation }}</p>
                        </div>
                      </div>
                      <div
                        style="
                          display: flex;
                          justify-content: space-between;
                          color: #666;
                        "
                      >
                        <div style="flex: 1.5" class="caption">
                          <strong>Mobile </strong>
                          <p>{{ guardianSelected.primary_mobile }}</p>
                        </div>
                        <div style="flex: 1" class="caption">
                          <strong>Profession </strong>
                          <p>
                            {{
                              (guardianSelected.occupation &&
                              guardianSelected.occupation !== "null"
                                ? guardianSelected.occupation
                                : "-") | capitalize
                            }}
                          </p>
                        </div>
                      </div>
                    </v-flex>
                  </v-layout>
                </v-timeline-item>
                <v-timeline-item
                  v-if="isStep3Complete"
                  :color="step !== 3 ? 'green' : 'red'"
                  small
                >
                  <v-layout pt-3>
                    <v-flex
                      :class="step !== 3 ? 'progress_shaded' : ''"
                      style="
                        margin-top: -10px;
                        margin-left: 0px;
                        margin-right: 5px;
                      "
                    >
                      <h3
                        style="
                          color: #444;
                          border-bottom: 1px dashed #ccc;
                          margin-bottom: 10px;
                          padding-bottom: 5px;
                        "
                      >
                        <strong> Address</strong>
                        <v-icon
                          style="font-size: 20px; color: #999; float: right"
                          >my_location
                        </v-icon>
                      </h3>
                      <strong style="font-size: 13px; color: #555"
                        >Permanent</strong
                      >
                      <div
                        class="caption"
                        style="color: #666; margin-bottom: 10px"
                      >
                        {{
                          $address.getName(
                            "state",
                            form.addressInfo.data.permanent_state_id
                          )
                        }},
                        {{
                          $address.getName(
                            "district",
                            form.addressInfo.data.permanent_district_id
                          )
                        }},
                        {{
                          $address.getName(
                            "city",
                            form.addressInfo.data.permanent_city_id
                          )
                        }}
                        - {{ form.addressInfo.data.permanent_ward_no }},
                        {{ form.addressInfo.data.permanent_address_line }}
                      </div>

                      <strong style="font-size: 13px; color: #555"
                        >Temporary</strong
                      >
                      <div class="caption" style="color: #666">
                        {{
                          $address.getName(
                            "state",
                            form.addressInfo.data.temporary_state_id
                          )
                        }},
                        {{
                          $address.getName(
                            "district",
                            form.addressInfo.data.temporary_district_id
                          )
                        }},
                        {{
                          $address.getName(
                            "city",
                            form.addressInfo.data.temporary_city_id
                          )
                        }}
                        - {{ form.addressInfo.data.temporary_ward_no }},
                        {{ form.addressInfo.data.temporary_address_line }}
                      </div>
                    </v-flex>
                  </v-layout>
                </v-timeline-item>
                <v-timeline-item
                  v-if="isStep4Complete"
                  :color="step !== 4 ? 'green' : 'red'"
                  small
                >
                  <v-layout pt-3>
                    <v-flex
                      :class="step !== 4 ? 'progress_shaded' : ''"
                      style="
                        margin-top: -10px;
                        margin-left: 0px;
                        margin-right: 5px;
                      "
                    >
                      <h3
                        style="
                          color: #444;
                          border-bottom: 1px dashed #ccc;
                          margin-bottom: 10px;
                        "
                      >
                        <strong>Enroll Information</strong>
                        <v-icon
                          style="font-size: 20px; color: #999; float: right"
                        >
                          group_add
                        </v-icon>
                      </h3>

                      <div
                        style="
                          display: flex;
                          justify-content: space-between;
                          color: #666;
                        "
                      >
                        <div style="flex: 1.5" class="caption">
                          <strong>Class/Section </strong>
                          <p>
                            {{ grade | getName(grades) }} /
                            {{ section | getName(sections) }}
                          </p>
                        </div>
                      </div>
                      <v-chip
                        style="float: left"
                        v-for="(item, key) in optionalSubName"
                        :key="key"
                        small
                        class="mr-2"
                        label
                      >
                        {{ item }}
                      </v-chip>
                    </v-flex>
                  </v-layout>
                </v-timeline-item>
              </v-timeline>
            </div>
          </div>
        </v-card>
      </v-flex>
    </v-layout>

    <v-dialog v-model="guardianForm.dialog" persistent max-width="750px">
      <v-card>
        <v-card-title class="primary white--text">
          <span class="title">Guardian Quick Add.</span>
        </v-card-title>
        <v-card-text class="pb-1">
          <v-form
            ref="form"
            @submit.prevent="addGuardian"
            @keydown.native="guardianForm.errors.clear($event.target.name)"
            @keyup.enter="addGuardian"
            lazy-validation
          >
            <v-container style="padding: 0" grid-list-md>
              <v-layout wrap pt-3>
                <v-flex xs4>
                  <v-text-field
                    solo
                    autocomplete="off"
                    label="First Name*"
                    required
                    class="pa-0"
                    v-model="guardianForm.fname"
                    name="name"
                    :error-messages="guardianForm.errors.get('fname')"
                  />
                </v-flex>
                <v-flex xs4>
                  <v-text-field
                    solo
                    autocomplete="off"
                    label="Middle Name"
                    required
                    class="pa-0"
                    v-model="guardianForm.mname"
                    name="rank"
                    :error-messages="guardianForm.errors.get('mname')"
                  />
                </v-flex>

                <v-flex xs4>
                  <v-text-field
                    solo
                    autocomplete="off"
                    label="Last Name*"
                    required
                    class="pa-0"
                    v-model="guardianForm.lname"
                    name="rank"
                    :error-messages="guardianForm.errors.get('lname')"
                  />
                </v-flex>
                <v-flex xs5>
                  <v-text-field
                    prepend-inner-icon="email"
                    solo
                    autocomplete="off"
                    label="Email"
                    required
                    class="pa-0"
                    v-model="guardianForm.email"
                    name="rank"
                    :error-messages="guardianForm.errors.get('email')"
                  />
                  <span style="color: red">
                    {{ guardianForm.errors.get("email") }}
                  </span>
                </v-flex>
                <v-flex xs3>
                  <v-text-field
                    solo
                    v-model="guardianForm.primary_mobile"
                    label="Mobile"
                    mask="##########"
                    :error-messages="guardianForm.errors.get('primary_mobile')"
                    hint="10 digits"
                    counter
                    prepend-inner-icon="mobile_screen_share"
                    @input="searchGuardianByNumber"
                  ></v-text-field>
                  <span style="color: red">
                    {{ guardianForm.errors.get("primary_mobile") }}
                  </span>
                </v-flex>
                <v-flex xs4>
                  <!-- browser-autocomplete="new-password" -->
                  <v-text-field
                    solo
                    autocomplete="off"
                    required
                    v-model="guardianForm.password"
                    :append-icon="p_show ? 'visibility' : 'visibility_off'"
                    :type="p_show ? 'text' : 'password'"
                    :error-messages="guardianForm.errors.get('password')"
                    name="password"
                    label="Password"
                    hint="Min 8 characters. Default 12345678"
                    @click:append="p_show = !p_show"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-divider></v-divider>
              <v-layout wrap pt-3>
                <v-flex pt-3 xs11>
                  <MultipleGuardianSelecet
                    :parentInfo="parents"
                  ></MultipleGuardianSelecet>
                </v-flex>
                <v-flex xs12>
                  <template>
                    <div style="color: #333">
                      <v-icon small>error_outline</v-icon>
                      Please Note
                      <strong></strong>

                      <p style="line-height: 10px">
                        <small style="color: #666; font-size: 10px">
                          The information added from here won't be sufficient.
                          <br />
                          Additional Guardian information can be updated from
                          <strong>`Member > Guardian`</strong>
                        </small>
                      </p>
                    </div>
                  </template>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="warning"
            outlined
            @click="
              (guardianForm.dialog = false),
                show_guardian == 1 ? guardianForm.reset() : '',
                (duplicateNumberLoader = false)
            "
            >Close
          </v-btn>
          <load-button
            @action="addGuardian()"
            :loader="duplicateNumberLoader"
            color="success"
            text
            >Add</load-button
          >

          <!-- <v-btn color="success" outlined @click="addGuardian">Add</v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog max-width="550px" v-model="showGuardianInfo">
      <v-card class="guardian-modal guardian-modal-section">
        <v-card-title class="primary white--text">
          <span class="title">Alert!!</span>
        </v-card-title>
        <div class="alert-message">
          <alert-message
            class="alert-message"
            title="Guardian Already Exists."
            :type="'warning'"
            extra-small
            message="Looks like this guardian already exists, click select to add this guardian."
          ></alert-message>
        </div>

        <v-card-text
          style="text-align: center"
          class="pb-1 pt-4 guardian-content"
          v-if="duplicateGuardianInfo != ''"
        >
          <div class="guardian-img">
            <img
              class="pb-2"
              style="border-radius: 50%"
              height="100px"
              width="100px"
              :src="duplicateGuardianInfo.image"
              alt="Guardian Image"
            />
          </div>

          <p class="guardian-name">{{ duplicateGuardianInfo.name }}</p>
          <p class="guardian-address">
            Permanent Address :
            {{ duplicateGuardianInfo.address.permanent.address }}
          </p>
          <p class="guardian-address">
            Temporary Address :
            {{ duplicateGuardianInfo.address.temporary.address }}
          </p>
          <p class="guardian-email">
            Email : {{ duplicateGuardianInfo.email }}
          </p>
          <p class="guardian-mobile">
            Mobile : {{ duplicateGuardianInfo.primary_mobile }}
          </p>
        </v-card-text>
        <v-card-actions class="bottom-cards">
          <!-- <v-spacer></v-spacer> -->
          <v-btn
            class="footer-buttons"
            color="warning"
            outlined
            @click="showGuardianInfo = false"
            >Close</v-btn
          >

          <!-- <load-button class="footer-buttons"  @action="SelectGuardian()" :loader="duplicateNumberLoader" color="success" text >Select</load-button> -->

          <v-btn
            class="footer-buttons"
            color="success"
            outlined
            @click="SelectGuardian()"
            >Select</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import data from "./module/data";
import watch from "./module/watch";
import computed from "./module/computed";
import methods from "./module/methods";
import Loader from "../../../../components/Loader.vue";
import MultipleGuardianSelecet from "../Profile/pages/MultipleGuardianSelecet.vue";
export default {
  components: { Loader, MultipleGuardianSelecet },
  name: "StudentAdmitComponent",
  data: () => data,
  watch: watch,
  computed: computed,
  methods: methods,
  mounted() {
    this.parents.push({
      name: "",
      contact: "",
      relation: "",
    });
    if (this.$route.query.id != undefined) {
      setTimeout(() => {
        this.show_guardian = 0;
        this.admission_request_id = this.$route.query.id;
        this.getRequestAdmissionInfo(this.$route.query.id);
      }, 500);
    }
    this.getStates();
    this.searchGuardians();
    this.getStudentGroup();
  },
  filters: {
    getName: (value, arr) => {
      let dt = arr.find((item) => item.value === value);
      if (!dt) return "";
      return dt.text;
    },
    getArrayName: (value, arr) => {
      let dt = arr.find((item) => item.id === value);
      if (!dt) return "";
      return dt.name;
    },
  },
  beforeDestroy() {
    // this.$store.commit('RESET');
  },
};
</script>

<style lang="scss">
.guardian-modal-section {
  align-items: center !important;
  padding-bottom: 10px;
  .alert-message {
    margin: 10px 30px 10px 10px;
  }
  .guardian-name {
    font-size: 24px;
    font-weight: 600;
    color: #000;
    margin-bottom: 6px;
  }
  .guardian-address {
    margin: 0px;
    color: #9b9a9a;
    font-size: 14px;
    font-weight: 500;
  }
  .guardian-email {
    margin-top: 10px;
    margin-bottom: 4px;
    font-weight: 600;
  }
  .guardian-mobile {
    margin-bottom: 6px;
    font-weight: 600;
  }
  &.guardian-content {
    text-align: center !important;
    .guardian-img {
      img {
        border-radius: 50%;
      }
    }
  }
  .bottom-cards {
    display: flex;
    align-items: center !important;
    justify-content: center;
    .footer-buttons {
      align-items: center !important;
      width: 30%;
      margin-bottom: 6px;
    }
  }
}

.white--text {
  .title {
  }
}
.info-student {
  width: 25%;
  // margin-left: 8px;
  // box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  // margin-right: 8px;
}

.info-student p {
  margin-bottom: 0 !important;
}

.v-timeline--dense:before {
  left: -5px !important;
}

.v-timeline-item__dot.v-timeline-item__dot--small {
  margin-left: -24px !important;
}

.v-timeline--align-top .v-timeline-item__dot--small {
  top: 0;
}

.v-stepper--vertical {
  padding-bottom: 60px;
}

.progress_shaded {
  opacity: 0.5;
}
.v-application--is-ltr .v-timeline--dense:not(.v-timeline--reverse):before,
.v-application--is-rtl .v-timeline--reverse.v-timeline--dense:before {
  left: 0 !important;
}
.v-progress-circular {
  margin: 1rem;
  margin-top: 20%;
  margin-left: 50%;
}
</style>
