import { mapActions } from 'vuex'
import { redirectDownloadUrl } from '../../../../../library/helpers';
const NepaliDate = require('nepali-date');

export default {
    ...mapActions(["newEnrollStudentCount"]),
    SelectGuardian(){
        this.form.familyInfo.data.guardian_id = this.duplicateGuardianInfo.id
        this.showGuardianInfo = false;
        this.guardianForm.dialog = false;
        this.searchGuardian = this .duplicateGuardianInfo.name
        this.checkGuardianExist = true
    },
    searchGuardianByNumber: function (v) {
        if (v.length == 10){
            this.duplicateNumberLoader = true
            this.$rest.get('/api/get-guardian-bynumber/' + v).then(({data}) => {
                if(Object.keys(data).length > 0){
                    this.showGuardianInfo = true;
                    this.duplicateGuardianInfo = data;
                    }
            }).catch(err => {
            }).finally(() => {
                this.duplicateNumberLoader = false
            })
        }
      },
    getStates() {
        this.states = this.statesData.map(item => {
            return { text: item.name, value: item.id }
        })
    },
    getDistricts() {
        this.permanent_districts = this.districtDataData.filter(item => item.state_id === this.form.addressInfo.data.permanent_state_id).map(item => {
            return { value: item.id, text: item.name }
        });
    },

    getTemporaryDistricts() {
        this.temporary_districts = this.districtDataData.filter(item => item.state_id === this.form.addressInfo.data.temporary_state_id).map(item => {
            return { value: item.id, text: item.name }
        });
    },

    getCities() {
        this.permanent_cities = this.cityData.filter(item => item.district_id === this.form.addressInfo.data.permanent_district_id).map(item => {
            return { value: item.id, text: item.name }
        });
    },

    getTemporaryCities() {
        this.temporary_cities = this.cityData.filter(item => item.district_id === this.form.addressInfo.data.temporary_district_id).map(item => {
            return { value: item.id, text: item.name }
        });
    },
    copyAddress(status) {
        if (status) {
            if (this.form.addressInfo.data.permanent_address_line && this.form.addressInfo.data.permanent_city_id) {
                let temp = {
                    state: this.form.addressInfo.data.permanent_state_id,
                    district: this.form.addressInfo.data.permanent_district_id,
                    city: this.form.addressInfo.data.permanent_city_id,
                    address: this.form.addressInfo.data.permanent_address_line,
                    ward_no: this.form.addressInfo.data.permanent_ward_no
                };
                let data = JSON.parse(JSON.stringify(temp));
                this.$set(this.form.addressInfo.data, 'temporary_state_id', data.state);
                this.$set(this.form.addressInfo.data, 'temporary_district_id', data.district);
                this.$set(this.form.addressInfo.data, 'temporary_city_id', data.city);
                this.$set(this.form.addressInfo.data, 'temporary_address_line', data.address);
                this.$set(this.form.addressInfo.data, 'temporary_ward_no', data.ward_no)
            }
        }
    },

    searchGuardians(value = null) {
        if (!value) return;
        this.loadingGuardian = true;
        let params = '';
        this.guardians = [];
        if (value) params = '?name=' + (value.replace('\D'));
        this.$rest.get('/api/member?type=guardian' + params).then(({ data }) => {
            this.guardians = data.data.map(item => {
                return {
                    text: item.name,
                    value: item.id,
                    image: item.image,
                    email: item.email,
                    dob: item.dob,
                    mobile: item.primary_mobile,
                    profession: item.profession,
                    gender: item.gender,
                    address: item.address
                }
            });
        }).finally(
            this.loadingGuardian = false)

    },
    getOptionalGroups() {
        if (this.grade) {
            this.$rest.get('/api/subject-optional?batchId=' + this.batch.id + '&gradeId=' + this.grade).then(({ data }) => {
                this.optional_groups = data.map(function (sub) {
                    return { value: sub.id, text: sub.name };
                });

            });
        }
    },
    getWardCount(type) {
        let $this = this;
        let totalWards = [];
        if (type === 'permament') {
            let city = this.cityData.filter(function (city, key) {
                return $this.form.addressInfo.data.permanent_city_id === city.id;
            });
            this.ward_count_permanent = [];
            if (city.length) {
                city = city[0];
                let totalNumberOfWards = parseInt(city.ward_count);
                for (let i = 1;i <= totalNumberOfWards;i++) {
                    totalWards.push(i);
                }
            }
            this.ward_count_permanent = totalWards;
        } else {
            let city = this.cityData.filter(function (city, key) {
                return $this.form.addressInfo.data.temporary_city_id === city.id;
            });
            this.ward_count_temporary = [];
            if (city.length) {
                city = city[0];
                let totalNumberOfWards = parseInt(city.ward_count);
                for (let i = 1;i <= totalNumberOfWards;i++) {
                    totalWards.push(i);
                }
            }
            this.ward_count_temporary = totalWards;
        }
    },

    getGrades() {
        let currentBatch = null;
        const $this = this;
        if (this.batches && this.batches.length)
            this.batches.map(function (batch) {
                if (batch.is_current_batch === true && $this.batch.id === batch.id) {
                    currentBatch = batch;
                }
            });

        if (Object.keys(currentBatch).length)
            this.$rest.get('/api/grade-level?level=' + this.base + '&batchId=' + currentBatch.id + "&slim=true&decending=false").then(({ data }) => {
                this.grades = data.map(item => {
                    return { value: item.id, text: item.name }
                });
            })
    },

    getSections() {
        if (this.grade)
            this.$rest.get('/api/section?batch=' + this.batch.id + '&grade=' + this.grade).then(({ data }) => {
                this.sections = data.data.map(item => {
                    return { value: item.id, text: item.name }
                });
            })
    },

    /**
     * Image Upload Handler
     */
    onPickFile() {
        this.$refs.image.click()
    },
    onFilePicked(data) {
        if (data) {
            const files = data;
            let fileName = files.name;
            if (fileName.lastIndexOf('.') <= 0) {
                this.$store.dispatch('notifyError', "Please upload a valid image file!");
                return
            }
            this.cropperImg.imgSrc = data.image;
            this.cropperImg.name = data.name;
        }

    },
    cropImage(data) {
        this.cropperImg.cropImg = data.image;
        this.cropperImg.uploadCropped = data.cropped;
    },
    resizeImage(fileReader, file) {
        if (file.size / 1024 <= 200) {
            this.imagePicker.image = file;
            return;
        }
        let canvas = document.createElement("canvas");
        let img = document.createElement("img");
        img.src = fileReader.result;

        img.onload = () => {
            let ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);

            let MAX_WIDTH = 1200;
            let MAX_HEIGHT = 1200;
            let width = img.width;
            let height = img.height;

            if (width > height) {
                if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                }
            } else {
                if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                }
            }
            canvas.width = width;
            canvas.height = height;
            let context = canvas.getContext("2d");
            context.drawImage(img, 0, 0, width, height);

            let dataurl = canvas.toDataURL("image/png", 0);
            let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            this.imagePicker.image = new Blob([u8arr], { type: mime });
        };
    },

    resetImagePicker() {
        this.imagePicker = {
            url: undefined,
            image: undefined,
            name: undefined
        };
        this.cropperImg.cropImg = '';
        this.cropperImg.name = '';
    },

    submitStudentInfoForm() {
        if (this.$refs.studentInfoForm.validate()) {
            this.step++
            if (this.show_guardian == 0) {
                this.guardianForm.dialog = true;
            }
        }
    },

    resetStudentInfoForm() {
        this.resetImagePicker();
        this.$refs.studentInfoForm.reset();
    },

    submitAddressInfoForm() {
        if (this.$refs.addressInfoForm.validate()) {
            this.isStepThreeComplete()
            this.step++
        }
    },


    submitFamilyInfoForm() {
        if (this.$refs.familyInfoForm.validate()) {
            this.parents = [];
            this.step++;
            if (this.show_guardian == 1) {
                const guardianAddressPermanent = this.guardianSelected.address ? this.guardianSelected.address.permanent : null;
                if (guardianAddressPermanent) {
                    this.getDistricts('permanent', this.form.addressInfo.data.permanent_state_id);
                    this.getCities('permanent', this.form.addressInfo.data.permanent_district_id);
                    this.getWardCount('permament', this.form.addressInfo.data.permanent_city_id);
                }
            } else {
                this.getRequestAdmissionInfo(this.admission_request_id)
            }
            this.parents.push({
                name: this.guardianSelected.name,
                contact: this.guardianSelected.primary_mobile,
                relation: this.form.familyInfo.data.relation,
            });
            this.getGrades();
            this.isStepTwoComplete()

        }
    },

    submitEnrollInfoForm() {
        if (this.$refs.enrollInfoForm.validate()) {
            // this.step++
        }
    },
    isStepTwoComplete() {
        if (!this.guardianSelected.name || !this.form.familyInfo.data.relation) {
            this.step_2_complete = false
        } else {
            this.step_2_complete = true;
        };
    },
    // submitAddressInfoForm
    isStepThreeComplete() {
        if (Object.keys(this.form.addressInfo.data).length < 10) {
            isStep3Complete = false;
        }
        // return false;

        if (this.form.addressInfo.data.permanent_state_id !== '' && this.form.addressInfo.data.permanent_district_id !== '' && this.form.addressInfo.data.permanent_city_id !== '' && this.form.addressInfo.data.permanent_ward_no !== '' && this.form.addressInfo.data.permanent_address_line !== '' && this.form.addressInfo.data.temporary_state_id !== '' && this.form.addressInfo.data.temporary_district_id !== '' && this.form.addressInfo.data.temporary_city_id !== '' && this.form.addressInfo.data.temporary_ward_no !== '' && this.form.addressInfo.data.temporary_address_line !== '') {
            this.isStep3Complete = true;
        }
    },

    admitStudent() {
        if (!this.$refs.enrollInfoForm.validate()) return false;
        this.progressDialog = true;
        this.loader = true;
        let formData = new FormData();
        /**
         * Student Info Data
         */
        formData.set('fname', this.form.studentInfo.data.fname);
        if (this.form.studentInfo.data.mname) formData.set('mname', this.form.studentInfo.data.mname);
        formData.set('lname', this.form.studentInfo.data.lname);
        formData.set('dob', this.form.studentInfo.data.dob);
        if (this.form.studentInfo.data.gender !== undefined) formData.set('gender', this.form.studentInfo.data.gender);
        if (this.form.studentInfo.data.religion !== undefined) formData.set('religion', this.form.studentInfo.data.religion);
        if (this.form.studentInfo.data.ethnicity !== undefined) formData.set('ethnicity', this.form.studentInfo.data.ethnicity);
        if (this.form.studentInfo.data.blood_group !== undefined) formData.set('blood_group', this.form.studentInfo.data.blood_group);

        //Check if a file was choosen.
        if (Object.keys(this.cropperImg.uploadCropped).length > 0)
            formData.set('image', this.cropperImg.uploadCropped);

        /**
         * Address Info Data
         */
        formData.append('pcity_id', this.form.addressInfo.data.permanent_city_id);
        formData.append('pdistrict_id', this.form.addressInfo.data.permanent_district_id);
        formData.append('pstate_id', this.form.addressInfo.data.permanent_state_id);
        formData.append('tdistrict_id', this.form.addressInfo.data.temporary_district_id);
        formData.append('tstate_id', this.form.addressInfo.data.temporary_state_id);
        formData.append('pstreet', this.form.addressInfo.data.permanent_address_line);
        formData.append('pward_no', this.form.addressInfo.data.permanent_ward_no);
        formData.append('tcity_id', this.form.addressInfo.data.temporary_city_id);
        formData.append('tstreet', this.form.addressInfo.data.temporary_address_line);
        formData.append('tward_no', this.form.addressInfo.data.temporary_ward_no);
        formData.append('admission_date', this.admission_date);
        formData.append('optional_sub', this.optional_sub.join(','));
        formData.append('parents',JSON.stringify(this.parents))

        /**
         * Guardian Info Data
         */
        if (!this.form.familyInfo.data.guardian && this.form.familyInfo.data.guardian_id !== undefined && this.form.familyInfo.data.guardian_id) {
            formData.set('guardian_id', this.form.familyInfo.data.guardian_id);
            formData.set('relation', this.form.familyInfo.data.relation);
        }

        /**
         * Enroll Info Data
         */
        formData.set('batch_id', this.batch.id);
        formData.set('grade_id', this.grade);
        formData.set('section_id', this.section);

        formData.set('show_guardian', this.show_guardian);
        formData.set('admission_requested_student_id', this.admission_request_id);

        formData.set('checkGuardianExist',this.checkGuardianExist);
        formData.set('student_group_id',this.studentGroupId);
        this.$rest.post(this.route, formData).then(response => {
            this.progressDialog = false;
            if (response.data.code === 201) {
                this.resetStudentInfoForm();
                this.resetImagePicker();
                this.$refs.addressInfoForm.reset();
                this.$refs.familyInfoForm.reset();
                this.$refs.enrollInfoForm.reset();
                this.form.studentInfo.data.admission_date = new NepaliDate(new Date()).format('YYYY-MM-DD');
                // this.admission_date = new NepaliDate(new Date()).format('YYYY-MM-DD');
                // this.admission_date_temp = new NepaliDate(new Date()).format('YYYY-MM-DD');

                this.step = 1;
                this.newEnrollStudentCount();
                this.$events.fire('notification', { message: 'Student Successfully Admitted' });
                this.loader = false;
            } else {
                if (response.data && response.data.message !== undefined && response.data.message !== null) {
                    this.errorDialog = true;
                    this.loader = false
                    this.form.error = response.message
                }
            }
            this.step_2_complete = false;
            this.isStep3Complete = false;
            if (this.show_guardian === 0) {
                this.$router.push({ name: 'admit' });
                this.show_guardian = 1;

            }
        }).catch(error => {
            this.progressDialog = false;
            this.loader = false
            this.errorDialog = true;
            this.form.error = error.message;
            if (error.student) this.duplicate = error.student
        });
    },
    addGuardian() {
        if(this.duplicateGuardianInfo == ''){
            this.$rest.post('/api/user/slim', this.guardianForm.data()).then(res => {
                if (res.status === 201) {
                    this.$events.fire('notification', { message: res.message });
                    this.guardianForm.dialog = false;
                    this.searchGuardians();
                    this.isLoading = false;
                    if (this.show_guardian == 0) {
                        this.searchGuardian = res.data.data.name;
                        this.form.familyInfo.data.guardian_id = res.data.data.id;
                    }
    
                }
            }).catch(err => {
    
                this.$events.fire('notification', { message: err.response.data.message, status: "error" });
                this.guardianForm.failure(err.response.data);
    
            });
        }else{
            this.showGuardianInfo=true
        }
    },
    viewStudentDetail(id) {
        this.$router.push({ name: 'Student', params: { studentID: id } })
    },

    capitalizeString(string) {
        if (string === undefined || string === null) return '';
        return string.charAt(0).toUpperCase() + string.slice(1)
    },

    getRequestAdmissionInfo(id) {
        this.$rest.get('/api/admission-request-info/' + id)
            .then(({ data }) => {
                const admission_info = data;
                this.form.studentInfo.data.fname = admission_info.student_first_name;
                this.form.studentInfo.data.mname = admission_info.student_middle_name;
                this.form.studentInfo.data.lname = admission_info.student_last_name;
                this.form.studentInfo.data.gender = admission_info.gender;
                this.form.studentInfo.data.dob = admission_info.dob;
                this.form.studentInfo.data.religion = admission_info.religion;
                this.form.studentInfo.data.ethnicity = admission_info.ethinicity;
                this.grade = admission_info.grade_id;
                this.guardianForm.fname = admission_info.guardian_first_name;
                this.guardianForm.mname = admission_info.guardian_middle_name;
                this.guardianForm.lname = admission_info.guardian_last_name;

                this.guardianForm.email = admission_info.guardian_details.email;
                this.guardianForm.primary_mobile = admission_info.guardian_details.contact == '' ? admission_info.guardian_details.phone : admission_info.guardian_details.contact


                this.form.addressInfo.data = {
                    temporary_state_id: parseInt(admission_info.temporary_address.state),
                    temporary_district_id: parseInt(admission_info.temporary_address.district),
                    temporary_city_id: parseInt(admission_info.temporary_address.city),
                    temporary_address_line: admission_info.temporary_address.street,
                    temporary_ward_no: parseInt(admission_info.temporary_address.ward),

                    permanent_state_id: parseInt(admission_info.permanent_address.state),
                    permanent_district_id: parseInt(admission_info.permanent_address.district),
                    permanent_city_id: parseInt(admission_info.permanent_address.city),
                    permanent_address_line: admission_info.permanent_address.street,
                    permanent_ward_no: parseInt(admission_info.permanent_address.ward),
                }
                this.form.familyInfo.data.relation = admission_info.guardian_details.relation;
            })
            .catch((e) => {
                this.$events.fire('notification', { message: e.response.data.message, status: 'error' });
                this.$router.push({ name: 'admit' });
            }).finally(() => {
                this.showloader = false;
            })
    },
    getStudentGroup(){
        this.$rest.get('/api/student-group')
        .then(({data})=>{
            this.studentGroups = data.data.map(item => {
                return {value: item.id, text: item.name}
            });
        })
    },
    downloadAdmissionForm(){
        redirectDownloadUrl({uri:'/print/academic/admisssion-form'})
    }
};